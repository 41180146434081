var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-container',[_c('v-row',{attrs:{"justify":"start"}},[_c('v-toolbar-title',{staticClass:"grey--text text--darken-4 font-weight-black my-5 ml-12"},[_vm._v(" Resident List ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search resident","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"grey--text text--darken-4 font-weight-black my-5 ml-12",on:{"click":_vm.refresh_resident_list}},[_vm._v(" Refresh ")])],1),_c('v-data-table',{staticClass:"elevation-23",attrs:{"headers":_vm.headers,"items":_vm.residents,"options":_vm.options,"server-items-length":_vm.totalResidents,"loading":_vm.loading,"loading-text":"Loading ..... Please wait","footer-props":{
            itemsPerPageOptions: [5, 10, 20, 40],
            itemsPerPageText: 'Residents per page',
          },"update:options":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""}},on),[_vm._v(" Actions ")])]}}],null,true)},[(_vm.$can('add'))?_c('v-list',{staticClass:"overflow-y-auto"},_vm._l((_vm.createList),function(list,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.controlDrugAdministration(item, list)}}},[_c('v-list-item-title',[_vm._v(_vm._s(list.title))])],1)}),1):_vm._e(),(_vm.$can('read'))?_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"100px"}},_vm._l((_vm.viewList),function(list,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.controlDrugAdministration(item, list)}}},[_c('v-list-item-title',[_vm._v(_vm._s(list.title))])],1)}),1):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }